<template>
  <div>
    <Breadcrumbs title="Student Cards" />
    <div class="container-fluid">
      <div class="row">

        <div class="col-12">
          <div class="card" v-if="user">
            <div class="card-body">

              <div class="row">

                <div class="col-12 col-lg-3">
                  <b-form-group label="Institute *">
                    <b-form-select @change="instituteChanged" class="form-control border" size="lg" v-model="form.institute_id" :options="user.institutes.filter(inst => inst.name !== 'Migration')" value-field="id" text-field="name" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- All Institutes --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3" v-if="teachers">
                  <b-form-group label="Teacher *">
                    <b-form-select @change="teacherChanged" class="form-control border" size="lg" v-model="form.teacher_id" :options="teachers" value-field="id" text-field="text" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- All Teachers --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3">
                  <b-form-group label="Class *">
                    <b-form-select class="form-control border" size="lg" :disabled="!class_details.length" v-model="form.class_detail_id" :options="class_details" value-field="id" text-field="text" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- All Classes --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="hasError('class_detail_id')">{{getError('class_detail_id')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3">
                  <b-form-group label="Student NIC">
                    <b-form-input v-model="form.nic" size="lg" class="btn-square border" @keydown.enter="searchStudentByNIC" required placeholder="(optional)" />
                    <b-form-invalid-feedback :state="hasError('nic')">{{getError('nic')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3">
                  <b-form-group label="Profile Photo *">
                    <b-form-select class="form-control border" size="lg" :disabled="!form.class_detail_id" v-model="form.profile_photo" :options="photoStatusOptions" required></b-form-select>
                    <b-form-invalid-feedback :state="hasError('profile_photo')">{{getError('profile_photo')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-12 col-lg-3">
                  <b-form-group label="Payment Status *">
                    <b-form-select class="form-control border" size="lg" :disabled="!form.class_detail_id" v-model="form.payment_status" :options="paymentStatusOptions" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- Payment Status --</b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="hasError('payment_status')">{{getError('payment_status')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-12 col-lg-3" v-show="form.payment_status!==null">
                  <b-form-group label="Payment Month">
                    <month-picker-input
                        @change="setPaymentMonth"
                        v-model="form.payment_month"
                        :input-pre-filled="true"
                        alignment="top"
                        selectedBackgroundColor="green"
                        style="z-index: 5;width: 100% !important;"
                    ></month-picker-input>
                    <p class="text-danger" v-if="!form.payment_month"><strong>Please Select Payment Month</strong></p>
                    <b-form-invalid-feedback :state="hasError('payment_month')">{{getError('payment_month')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                 <div class="col-12 col-lg-3">
                  <b-form-group label="Card Status">
                    <b-form-select class="form-control border" size="lg" :disabled="!form.class_detail_id" v-model="form.card_status" :options="cartGenerateOption" required>
                      <template #first>
                        <b-form-select-option :value="null" selected="selected" disabled>-- Card Status --</b-form-select-option>
                      </template>
                      
                    </b-form-select>
                    <p class="text-danger" v-if="!form.card_status"><strong>Please Select Status</strong></p>
                    <b-form-invalid-feedback :state="hasError('card_status')">{{getError('card_status')}}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                

                <div class="col-12 text-right">
                  <b-button variant="primary" class="mx-1" :disabled="pdf_loading" @click="fetchStudents">Submit</b-button>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
<!--              <b-checkbox-group v-model="selected">-->
              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                >

                  <template #head(checkbox)="row" class="text-center">
                    <div class="checkbox-animated col-12 col-md-6 col-lg-4">
                      <label class="d-block">
                        <input class="checkbox_animated" name="institutes" type="checkbox"
                               v-model="select_all"
                               :checked="select_all"
                               :value="1"
                               @change="toggleAllChecks"
                        >
                      </label>
                    </div>
                  </template>
                  <template #cell(checkbox)="row" class="text-center">
                    <div class="checkbox-animated col-12 col-md-6 col-lg-4">
                      <label class="d-block">
                        <input class="checkbox_animated" name="institutes" type="checkbox"
                               v-model="selected"
                               :checked="selected.includes(row.item.id)"
                               :value="row.item.id"
                        >
                      </label>
                    </div>
<!--                    <b-form-checkbox v-model="selected" :value="row.item.id" :checked="selected.indexOf(row.item.id)>-1" :key="row.item.id">{{selected.indexOf(row.item.id)>-1}}</b-form-checkbox>-->
<!--                    <b-form-checkbox name="selected_ids" v-modal="selectedRows" :value="row.item.id"></b-form-checkbox>-->
<!--                    <b-button v-show="checkUserPermissions(user,['update_zoom_configs'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>-->
                  </template>
                  <template #cell(image)="row" class="text-center">
                    <div class="checkbox-animated col-12 col-md-6 col-lg-4">
                      <label class="d-block">
                        <img v-if="row.item.image_url" style="width:130px" class=" img-fluid" :src="'https://api.gurumandala.lk/storage/photos/'+row.item.image_url"/>
                        <img v-else style="width:130px" class=" img-fluid" :src="'https://www.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png'"/>
                      </label>
                    </div>
                  </template>
                </b-table>
              </div>
<!--              </b-checkbox-group>-->

              <b-row >
                <b-col cols="4" v-if="tableRecords.length">
                  
                  <b-button variant="danger" class="mx-1" :disabled="pdf_loading || !selected.length" @click="generateCards">Generate</b-button>
                  <strong class="ml-2">{{ totalRows }}</strong> Records
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>

            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card" v-if="pdf_loaded">
            <div class="card-body">
              <iframe width="100%" height="1200px" :src="base64_data" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import API from '@/services/api'
import moment from "moment";

export default {
  name: "StudentCardsGenerate",
  data() {
    return {
      errorBag: null,
      filter: '',
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],
      tableFields: [
        { key: 'checkbox', label: '', sortable: false, },
        { key: 'image', label: 'IMAGE', sortable: true, },
        { key: 'id', label: 'ID', sortable: true, },
        { key: 'institute_name', label: 'Institute', sortable: true, },
        { key: 'nic', label: 'NIC', sortable: true, },
        { key: 'first_name', label: 'First Name', sortable: true, },
        { key: 'last_name', label: 'Last Name', sortable: true, },
        { key: 'username', label: 'Username', sortable: true, },
        // { key: 'active', label: 'Active', sortable: true, class: 'text-center' },
        // { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],
      paymentStatusOptions: [
        {value: null, text: 'All'},
        {value: 1, text: 'Paid'},
        {value: 0, text: 'Unpaid'},
      ],
      cartGenerateOption: [
        {value: 0, text: 'All'},
        {value: 1, text: 'Generated'},
        {value: 2, text: 'No Generated'},
      ],
      form : {
        institute_id: 1,
        teacher_id: null,
        class_detail_id: null,
        payment_status: null,
        card_status:null,
        payment_month: null,
        profile_photo: 1,
        nic: null
      },
      default_year: parseInt(moment().format('Y'), 10),
      default_month: parseInt(moment().format('M'), 10),
      pdf_loaded: false,
      pdf_loading: false,
      base64_data: '',
      select_all: false,
      selectedStudents: [],
      photoStatusOptions: [
        { value: 1, text: "Yes" },
        { value: 0, text: "No" }
      ]
    }
  },
  created() {
    this.$store.dispatch('auth/getUser')
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    this.$store.dispatch('select/fetchRecords', { id: 'teachers' })
  },
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/authenticated',
    teachers: 'select/teachers',
    institutes: 'select/institutes',
    class_details: 'select/class_details',
  }),
  methods: {
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    assignNIC(value){ this.nic = value },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.items.count = filteredItems.length
    },

    async instituteChanged() {
      this.form.class_detail_id = null
      await this.$store.dispatch('select/fetchRecords', { id: 'teachers', params: { institute_id: this.form.institute_id } })
    },

    async teacherChanged() {
      this.form.class_detail_id = null
      await this.$store.dispatch('select/fetchRecords', { id: 'class-details', params: { institute_id: this.form.institute_id, teacher_id: this.form.teacher_id } })
    },

    searchStudentByNIC() {
      if(this.form.nic.length > 3) {
        this.searchStudent()
      }
    },
    async searchStudent() {
      console.log('form', this.form)
      try {
        this.pdf_loaded = false
        this.base64_data = ''
        const params = {
          ...this.form,
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter
        }
        await API.get('/api/backend/student-cards', params).then(response => {
          console.log('response', response.data.data)
          if (response.data.success) {
            this.totalRows = response.data.data.total
            this.tableRecords = response.data.data.data
          } else {
            this.errorBag = response.data
          }
        }).catch(error => {
          console.log('error', error)
        })
      } catch (error) {
        console.log('catch_error', error)
      } finally {
        this.pdf_loaded = true
      }

    },
    async generateCards() {
      console.log('form', this.form)
      this.pdf_loaded = false
      this.pdf_loading = true
      this.base64_data = ''
      try {
        const params = {
          ...this.form,
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          preview: false,
          selected: this.selected,
        }
        await API.post('/api/backend/student-cards', params)
            .then(response => {
              console.log('response', response.data)
              this.base64_data = response.data
              this.pdf_loaded = true
            }).catch(error => {
              console.log('error', error)
            }).finally(() => {
              this.pdf_loading = false
            })
      } catch (error) {
        this.pdf_loading = false
        console.log('catch_error', error)
      }

    },

    async previewCards() {
      this.pdf_loaded = false
      this.pdf_loading = true
      this.base64_data = ''
      try {
        const params = {
          ...this.form,
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          preview: true,
          selected: this.selected
        }

       await API.post('/api/backend/student-cards', params)
            .then(response => {
              console.log('response', response.data)
              this.base64_data = response.data
              this.pdf_loaded = true
            }).catch(error => {
              console.log('error', error)
            }).finally(() => {
              this.pdf_loading = false
            })
      } catch (error) {
        this.pdf_loading = false
        console.log('catch_error', error)
      }
    },


    async fetchStudents() {
      this.pdf_loaded = false
      this.pdf_loading = true
      this.base64_data = ''
      this.select_all = false
      this.selected = []
      try {
        const params = {
          ...this.form,
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          selected: this.selected,
          preview: true,
        }
        await API.post('/api/backend/student-cards-data', params)
            .then(response => {
              console.log('response', response.data)
              this.tableRecords = response.data.data
              this.totalRows = response.data.data.length
              // this.perPage = response.data.data.length
            }).catch(error => {
              console.log('error', error)
            }).finally(() => {
              this.pdf_loading = false
            })
      } catch (error) {
        this.pdf_loading = false
        console.log('catch_error', error)
      }
    },
    setPaymentMonth(date) {
      console.log('date',date);
      this.payment_month = date
    },
    toggleAllChecks() {
      if (this.select_all) {
        this.selected = this.tableRecords.map(item => item.id)
      } else {
        this.selected = []
      }
    }
  }
}
</script>

<style scoped>

</style>
